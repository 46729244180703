import React, { useState, useEffect, useMemo } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

import MiniCart from "./MiniCart"
import Popover from "react-tiny-popover"
import ProfileIcon from "../../../../static/Profile.svg"
import MenuIcon from "../../../../static/menuIcon.svg"
import MenuCloseIcon from "../../../../static/menuClose.svg"
import MiniCartPopOver from "./MiniCartPopOver"
import MobileMenuComp from "./MobileMenuComp"
import { isBrowser } from "../../../context/ApolloContext"
import { withUserContext } from "../../../context/UserContext"
import theme from "../../../utils/theme"

const NavbarItemTitle = styled(Link)`
  border: 0;
  font-family: ${({ active }) =>
    active === "active" ? "Gotham" : "GothamLight"};
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
  color: #262626;
  display: flex;
  justify-content: center;
  transition: opacity 250ms;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin: 0px 20px 0px 0px;
  // text-transform: uppercase;

  &:hover,
  &:focus {
    font-family: Gotham;
    font-weight: bold;

    &:after {
      display: block;
    }
  }

  sup {
    line-height: 1;
    font-style: italic;
  }

  &:after {
    content: "";
    display: none;
    position: absolute;
    bottom: -2px;
    left: 0px;
    width: 100%;
    border-top: 2px solid #262626;
  }

  @media (max-width: 1950px) {
    margin: 0px 15px 0px 0px;
    font-size: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "14px"
        : "16px"};
  }

  @media (max-width: 1820px) {
    margin: 0px 12px 0px 0px;
    font-size: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "14px"
        : "16px"};
  }

  @media (max-width: 1670px) {
    margin: 0px 12px 0px 0px;
    font-size: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "13px"
        : "16px"};
  }

  @media (max-width: 1600px) {
    margin: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "0px 10px 0px 0px"
        : "0px 12px 0px 0px"};
    font-size: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "12px"
        : "15px"};
  }

  @media (max-width: 1375px) {
    margin: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "0px 8px 0px 0px"
        : "0px 15px 0px 0px"};
    font-size: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "12px"
        : "13px"};
  }

  @media (max-width: 1300px) {
    margin: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "0px 8px 0px 0px"
        : "0px 15px 0px 0px"};
    font-size: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "11px"
        : "13px"};
  }

  @media (max-width: 1260px) {
    margin: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "0px 10px 0px 0px"
        : "0px 15px 0px 0px"};
    font-size: ${({ lang, store }) =>
      (store === "general" || store === "eur") && lang === "fr"
        ? "10px"
        : "13px"};
  }

  @media (max-width: 850px) {
    padding: 15px 20px;
    line-height: 24px;
    justify-content: flex-start;
    font-size: 0.9em;
    margin: 0 15px;
  }

  @media (max-width: 550px) {
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      font-size: ${({ lang, store }) =>
        (store === "general" || store === "eur") && lang === "fr"
          ? "14px"
          : "16px"};
      font-family: "Gotham";
    }
  }
  /* Test website on real Safari 11+ */

  /* Safari 10.1 */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
      font-size: ${({ lang, store }) =>
        (store === "general" || store === "eur") && lang === "fr"
          ? "14px"
          : "16px"};
      font-family: "Gotham";
    }
  }

  /* Safari 6.1-10.0 (but not 10.1) */
  @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
    @media {
      font-size: ${({ lang, store }) =>
        (store === "general" || store === "eur") && lang === "fr"
          ? "14px"
          : "16px"};
      font-family: "Gotham";
    }
  }
`

const NavbarItemTitleLink = styled.a`
  border: 0;
  font-family: ${({ active }) =>
    active === "active" ? "Gotham" : "GothamLight"};
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  color: #262626;
  display: flex;
  justify-content: center;
  transition: opacity 250ms;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin: 0px 20px 0px 0px;
  // text-transform: uppercase;

  &:hover,
  &:focus {
    font-family: ${({ active }) =>
      active === "active" ? "Gotham" : "GothamLight"};
    font-weight: bold;

    &:after {
      display: block;
    }
  }

  &:after {
    content: "";
    display: none;
    position: absolute;
    bottom: -2px;
    left: 0px;
    width: 100%;
    border-top: 2px solid #262626;
  }

  @media (max-width: 1700px) {
    margin: 0px 15px 0px 0px;
    font-size: 14px;
  }

  @media (max-width: 1450px) {
    font-size: 13px;
  }

  @media (max-width: 1350px) {
    margin: 0px 11px 0px 0px;
    font-size: 13px;
  }

  @media (max-width: 850px) {
    padding: 15px 20px;
    line-height: 24px;
    justify-content: flex-start;
    font-size: 0.9em;
    margin: 0 15px;
  }

  @media (max-width: 550px) {
  }
`

const NavbarItemEl = styled.li`
  margin-bottom: 0;

  > a:hover {
    font-family: "Gotham";
  }

  &:nth-last-of-type(2) {
    @media (max-width: 1600px) {
      display: ${({ lang, store, exclusifPromo }) =>
        exclusifPromo
          ? "block"
          : (store === "general" || store === "eur") && lang === "fr"
          ? "none"
          : "block"};
    }
  }
`

const DropdownSlot = styled.div`
  position: absolute;
  left: 0%;
  perspective: 1000px;
  z-index: 1;

  @media (max-width: 850px) {
    position: relative;
  }
`

const NavbarItem = ({
  title,
  index,
  children,
  hasChild,
  onMouseEnter,
  onMouseLeave,
  active,
  link,
  rel,
  intl,
  exclusifPromo,
}) => {
  const handleMouseEnter = () => onMouseEnter(index)
  return (
    <NavbarItemEl
      onMouseEnter={handleMouseEnter}
      onFocus={handleMouseEnter}
      store={process.env.GATSBY_ACTIVE_ENV}
      lang={intl.locale}
      exclusifPromo={exclusifPromo}
    >
      {link && link.indexOf("http") == 0 ? (
        <NavbarItemTitleLink
          href={link}
          rel={rel}
          onClick={onMouseLeave}
          haschildren={hasChild ? "true" : null}
          target="blank"
          active={active}
          store={process.env.GATSBY_ACTIVE_ENV}
          lang={intl.locale}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </NavbarItemTitleLink>
      ) : link.indexOf("javascript") == 0 ? (
        <NavbarItemTitleLink
          onClick={onMouseLeave}
          haschildren={hasChild ? "true" : null}
          active={active}
          store={process.env.GATSBY_ACTIVE_ENV}
          lang={intl.locale}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </NavbarItemTitleLink>
      ) : (
        <NavbarItemTitle
          to={link}
          onClick={onMouseLeave}
          haschildren={hasChild ? "true" : null}
          active={active}
          store={process.env.GATSBY_ACTIVE_ENV}
          lang={intl.locale}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </NavbarItemTitle>
      )}
      <DropdownSlot>{children}</DropdownSlot>
    </NavbarItemEl>
  )
}

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
`

const NavbarEl = styled.nav`
  display: flex;
  margin: 0 auto;
  width: 100%;
  background: ${props => props.theme.colors.white.base};
  padding: 0px 15px;
  height: 71px;
  align-items: center;
  justify-content: center;
  position: relative;
  justify-content: space-between;

  > img {
    margin: 4px 0px;
  }
  > a {
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 1200px) {
      flex: 1;
      justify-content: flex-end;
      justify-content: flex-start !important;
    }
  }

  @media (max-width: 850px) {
    height: 55px;
    box-shadow: none;
  }
`

const NavbarList = styled.ul`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0px;
  width: 100%;
  // max-width: 1400px;
  position: relative;
  // margin-top: -20px;
  margin-left: 25px;

  div:first-of-type {
    margin-left: 0;

    li:first-of-type a {
      color: ${({ store, lang, exclusifPromo }) =>
        exclusifPromo === "exclu"
          ? theme.colors.primary.enplus
          : exclusifPromo === "exclusif"
          ? theme.colors.primary.exclusif
          : (store === "eur" || store === "general") && lang === "fr"
          ? theme.saleColor.menuColor
          : "#262626"};
      :after {
        border-color: ${({ store, lang, exclusifPromo }) =>
          exclusifPromo === "exclu"
            ? theme.colors.primary.enplus
            : exclusifPromo === "exclusif"
            ? theme.colors.primary.exclusif
            : (store === "eur" || store === "general") && lang === "fr"
            ? theme.saleColor.menuColor
            : "#262626"};
      }

      :hover {
        :after {
          border-color: ${({ store, lang, exclusifPromo }) =>
            exclusifPromo === "exclu"
              ? theme.colors.primary.enplus
              : exclusifPromo === "exclusif"
              ? theme.colors.primary.exclusif
              : (store === "eur" || store === "general") && lang === "fr"
              ? theme.saleColor.menuColor
              : "#262626"};
        }
      }
    }
  }

  div:last-of-type {
    li:nth-last-of-type(2) {
      @media (max-width: 1600px) {
        display: block;
      }
    }
    li:last-of-type a {
      color: #24b2db;

      :after {
        border-color: #24b2db;
      }

      :hover {
        :after {
          border-color: #24b2db;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    margin-left: 20px;
  }

  @media (max-width: 1360px) {
    margin-left: 10px;
  }

  @media (max-width: 1200px) {
    position: relative;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  }
`

const ExclusfiLogoWrapper = styled.div`
  display: flex;
  z-index: 1;

  @media (max-width: 1200px) {
    flex: 1;
  }
`

const Logo = styled.img`
  width: 52px;
  height: 65px;
  align-self: center;
  padding: 3px 0;
  margin: 0;

  @media (max-width: 1200px) {
    width: 40px;
    height: 52px;
  }
`

const MobileMenuIcon = styled.div`
  width: 25px;
  color: #000;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  > span {
    height: 3px;
    background: #000;
    margin: 2px 0px;
  }

  img {
    margin: 0px;
    max-width: 25px;
    max-height: 25px;
  }
  @media (max-width: 900px) {
    width: 25px;
    > span {
      height: 2px;
      background: #000;
      margin: 2px 0px;
    }
  }
`

const MobileMenuText = styled.div`
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  margin-top: 5px;
  text-transform: uppercase;
`

const MobileBoutiqueWrapper = styled.div`
  display: none;
  flex-direction: column;
  height: 42px;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    display: flex;
  }
`

const BoutiqueIcon = styled.img`
  width: 100%;
  margin: 0px auto;
`

const MobileMenuWrapper = styled.div`
  display: none;
  top: 0;
  left: 0;
  width: ${({ show }) => (show ? "100%" : 0)};
  height: 100%;
  z-index: 999;
  overflow: hidden;
  position: fixed;
  background: rgba(11, 11, 11, 0.6);
  transition: opacity 0.3s, display 0.3s;

  @media (max-width: 1200px) {
    display: block;
  }
`

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: ${({ open }) => (open ? "100%" : "0px")};
  background-color: rgba(255, 255, 255, 1);
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-transform: ${({ open }) =>
    open ? "translateX(0px)" : "translateX(-260px)"};
  transform: ${({ open }) => (open ? "translateX(0px)" : "translateX(-260px)")};
  z-index: 9;
  transition: all linear 0.3s;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    top: 105px;
  }

  @media (max-width: 850px) {
    top: 88px;
  }
`

const MenuCloseButton = styled.div`
  display: flex;

  :hover {
    color: #262626;
    opacity: 1;
  }

  img {
    margin: 0px;
    max-width: 25px;
    max-height: 25px;
  }
`

const PopOverWrapper = styled(Popover)`
  top: 100px !important;
  right: 0px !important;
`

const MiniCartWrapperMobile = styled.div`
  display: inherit;
  z-index: 1;

  @media (max-width: 1200px) {
    display: none;
  }
`

const MCartMenuWrapper = styled.div`
  display: none;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
`

const InnerDiv = styled.div`
  height: 100%;
  background-color: white;
  width: ${({ show }) => (show ? "270px" : "0px")};
  float: right;
  transition: width 0.3s;
  padding-top: 20px;
`

const Navbar = ({ intl, children, onMouseLeave, loggedIn, exclusifPromo }) => {
  const [openCloseMenu, setopenCloseMenu] = useState(false)
  const [MinicartUIOpen, setMinicartUIOpen] = useState(false)
  const [MobileMiniState, setMobileMiniState] = useState(false)
  const isNotCart =
    isBrowser &&
    !window.location.pathname.includes("panier") &&
    !window.location.pathname.includes("cart")
  const $ = isBrowser ? require("jquery") : {}

  const isMobileMenu = () => {
    $(document).ready(function() {
      if (openCloseMenu) {
        $("body").addClass("fixed-position")
      } else {
        $("body").removeClass("fixed-position")
      }
    })
  }

  const cartUrl = () => {
    let url = ""
    if (intl.locale === "fr") {
      url = "/panier/"
    } else {
      url = "/cart/"
    }
    return url
  }

  useEffect(() => {
    isBrowser && isMobileMenu()
  }, [openCloseMenu])
  return (
    <NavWrapper>
      <MobileMenu open={openCloseMenu}>
        <MobileMenuComp
          openCloseMenu={setopenCloseMenu}
          exclusifPromo={exclusifPromo}
        />
      </MobileMenu>
      <NavbarEl onMouseLeave={onMouseLeave}>
        <div
          className="mobile_display"
          style={{
            flexDirection: "column",
            alignItems: "flex-start",
            flex: "inherit",
            justifyContent: "flex-end",
            marginRight: 20,
          }}
        >
          {openCloseMenu ? (
            <MenuCloseButton onClick={() => setopenCloseMenu(false)}>
              {/* <img src="https://static.percko.com/uploads/6a59c224-79f9-403b-b3fe-610732b9d4ab.png" /> */}
              <img className="lazyload" data-src={MenuCloseIcon} />
            </MenuCloseButton>
          ) : (
            <MobileMenuIcon onClick={() => setopenCloseMenu(!openCloseMenu)}>
              <img className="lazyload" data-src={MenuIcon} />
            </MobileMenuIcon>
          )}

          {/* <MobileMenuText>
            {intl.formatMessage({ id: "menu__mobile_hamburger_name" })}
          </MobileMenuText> */}
        </div>
        {exclusifPromo ? (
          <ExclusfiLogoWrapper>
            <Logo
              className="lazyload"
              data-src="https://static.percko.com/uploads/e0d0dd8d-277b-4edc-9440-4f8109cf2c36.jpeg"
            />
          </ExclusfiLogoWrapper>
        ) : (
          <Link style={{ justifyContent: "center", zIndex: 1 }} to={`/`}>
            <Logo src="https://static.percko.com/uploads/e0d0dd8d-277b-4edc-9440-4f8109cf2c36.jpeg" />
          </Link>
        )}
        <MobileBoutiqueWrapper>
          <Link to={`${intl.locale === "fr" ? "/mon-compte/" : "/dashboard/"}`}>
            <MobileMenuIcon style={{ margin: "0px auto" }}>
              <BoutiqueIcon className="lazyload" data-src={ProfileIcon} />
            </MobileMenuIcon>
            <MobileMenuText>
              {loggedIn
                ? intl.formatMessage({ id: "mobile_loggedIn" })
                : intl.formatMessage({ id: "login" })}
            </MobileMenuText>
          </Link>
        </MobileBoutiqueWrapper>
        <NavbarList
          store={process.env.GATSBY_ACTIVE_ENV}
          lang={intl.locale}
          className="desktop_display"
          exclusifPromo={exclusifPromo}
        >
          {children}
        </NavbarList>
        <PopOverWrapper
          containerClassName={"popover"}
          isOpen={MinicartUIOpen}
          position={["bottom", "right"]}
          onClickOutside={() => setMinicartUIOpen(false)}
          content={
            <MiniCartPopOver
              exclusifPromo={exclusifPromo}
              onMouseEnter={() => setMinicartUIOpen(true)}
              onMouseLeave={() => setMinicartUIOpen(false)}
            />
          }
        >
          <MiniCartWrapperMobile>
            <Link
              onMouseEnter={() => setMinicartUIOpen(true)}
              onMouseLeave={() => setMinicartUIOpen(false)}
              to={cartUrl()}
            >
              <MiniCart />
            </Link>
          </MiniCartWrapperMobile>
        </PopOverWrapper>
        <MCartMenuWrapper
          onClick={() => isNotCart && setMobileMiniState(!MobileMiniState)}
        >
          <MiniCart />
        </MCartMenuWrapper>
        {isNotCart && (
          <MobileMenuWrapper show={MobileMiniState}>
            <InnerDiv show={MobileMiniState}>
              <MiniCartPopOver handleClose={() => setMobileMiniState(false)} />
            </InnerDiv>
          </MobileMenuWrapper>
        )}
      </NavbarEl>
    </NavWrapper>
  )
}

const AnimatedNavbar = ({ intl, userContext, exclusifPromo }) => {
  // const [activeIndices, setActiveIndices] = useState(0)
  const [activeIndices, setActiveIndices] = useState("")

  const { profile } = userContext
  const isLoggedIn = Object.keys(profile).length !== 0

  const navbarConfig = intl => {
    return [
      {
        intl_id: exclusifPromo
          ? "menu_our_product_offres_exclusif"
          : process.env &&
            (process.env.GATSBY_ACTIVE_ENV === "eur" ||
              process.env.GATSBY_ACTIVE_ENV === "general") &&
            intl.locale === "fr"
          ? "menu_our_product_offres"
          : null,
        dropdown: null,
        link:
          exclusifPromo === "exclu"
            ? "/shop/offres-enplus/?discount_code=exclu"
            : exclusifPromo === "exclusif"
            ? "/shop/offres-exclusives/?discount_code=exclusif"
            : "/shop/offres/",
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? null
            : "menu_our_product_lyneup",
        dropdown: null,
        link:
          intl.locale === "fr"
            ? "/shop/lyneup-test/"
            : intl.locale === "en"
            ? "/shop/lyneup-test/"
            : "/shop/lyneup-2b/",
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          exclusifPromo
            ? "menu_our_product_lyneup"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclu"
            ? "/shop/lyneup-enplus/?discount_code=exclu"
            : "/shop/solutions-textiles-exclusif/?discount_code=exclusif&Gender=Female&Type=Sous-vetement",
      },
      {
        intl_id:
          !exclusifPromo &&
          process.env &&
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? "menu_our_product_chaise"
            : exclusifPromo === "exclusif"
            ? "menu_our_product_chaise"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclusif"
            ? "/shop/chaise-bureau-ergonomique-exclusif/"
            : "/shop/chaise-bureau-ergonomique/",
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          !exclusifPromo
            ? "menu_solution_lyne"
            : null,
        dropdown: null,
        link: "/shop/solutions-textiles/",
      },
      {
        intl_id:
          process.env &&
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? "menu_our_product_matelas"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclu"
            ? "/shop/matelas-enplus/?discount_code=exclu"
            : exclusifPromo === "exclusif"
            ? "/shop/matelas-exclusif/?discount_code=exclusif"
            : "/shop/matelas/",
      },
      {
        intl_id:
          process.env &&
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? "menu_our_product_sommier"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclu"
            ? "/shop/sommiers-zones-enplus/?discount_code=exclu"
            : exclusifPromo === "exclusif"
            ? "/shop/sommiers-zones-exclusifs/?discount_code=exclusif"
            : "/shop/sommiers-zones/",
      },
      {
        intl_id:
          exclusifPromo && exclusifPromo == "exclusif"
            ? "menu_our_product_surmatelas"
            : !exclusifPromo &&
              process.env &&
              (process.env.GATSBY_ACTIVE_ENV === "eur" ||
                process.env.GATSBY_ACTIVE_ENV === "general") &&
              intl.locale === "fr"
            ? "menu_our_product_surmatelas"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclusif"
            ? "/shop/surmatelas-exclusif/?discount_code=exclusif"
            : "/shop/surmatelas/",
      },
      {
        intl_id:
          exclusifPromo && exclusifPromo == "exclusif"
            ? "menu_our_product_oreiller"
            : !exclusifPromo &&
              process.env &&
              (process.env.GATSBY_ACTIVE_ENV === "eur" ||
                process.env.GATSBY_ACTIVE_ENV === "general") &&
              intl.locale === "fr"
            ? "menu_our_product_oreiller"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclusif"
            ? "/shop/oreillers-ergonomiques-exclusif/?discount_code=exclusif"
            : "/shop/oreillers-ergonomiques/",
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          !exclusifPromo
            ? null
            : exclusifPromo
            ? null
            : "menu_our_product_lynehome",
        dropdown: null,
        link: "/shop/lynehome/",
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          !exclusifPromo
            ? null
            : exclusifPromo
            ? null
            : "menu_our_product_lynefit",
        dropdown: null,
        link: "/shop/lynefit/",
      },
      {
        intl_id: exclusifPromo
          ? null
          : process.env &&
            (process.env.GATSBY_ACTIVE_ENV === "eur" ||
              process.env.GATSBY_ACTIVE_ENV === "gbp" ||
              process.env.GATSBY_ACTIVE_ENV === "general") &&
            (intl.locale === "fr" || intl.locale === "en")
          ? "menu_our_product_loop"
          : null,
        dropdown: null,
        link: "/shop/loop/",
      },
      {
        intl_id: exclusifPromo ? null : "mobile_menu_our_products",
        dropdown: null,
        link: "/shop/",
      },
    ]
  }

  const secondNavbarConfig = intl => {
    return [
      {
        intl_id:
          !exclusifPromo &&
          process.env &&
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? "menu_showroom"
            : null,
        dropdown: null,
        link:
          "/showroom-paris/?utm_medium=site&utm_source=menu&utm_campaign=showroom-paris-10e",
      },
      {
        intl_id: exclusifPromo ? null : "menu__medical_validation",
        dropdown: null,
        link:
          intl.locale === "fr" ? "/dispositif-medical/" : "/scientific-study/",
      },
    ]
  }

  const onMouseEnter = i => {
    if (activeIndices === i) return
    // const dropdown = navbarConfig(intl)[i].dropdown
    // setActiveIndices(dropdown ? i : 0)
    setActiveIndices(i)
  }

  const onMouseLeave = () => {
    setActiveIndices("")
  }

  const CurrentDropdown = useMemo(() => {
    if (typeof activeIndices === "number") {
      if (activeIndices == 1) {
        return navbarConfig(intl)[0].dropdown
      } else {
        return navbarConfig(intl)[activeIndices].dropdown
      }
    }
  }, [activeIndices])

  return (
    <Navbar
      intl={intl}
      onMouseLeave={onMouseLeave}
      loggedIn={isLoggedIn}
      exclusifPromo={exclusifPromo}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flex: 1,
        }}
      >
        {navbarConfig(intl).map((n, index) => {
          return (
            n.intl_id && (
              <NavbarItem
                hasChild={!!navbarConfig(intl)[index].dropdown}
                key={index.toString()}
                title={intl.formatMessage({ id: n.intl_id })}
                index={index}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                active={activeIndices === index ? "active" : ""}
                link={n.link}
                rel={n.rel}
                intl={intl}
                exclusifPromo={exclusifPromo}
              >
                {/* {activeIndices === index && CurrentDropdown && (
                <CurrentDropdown onMouseLeave={onMouseLeave} />
              )} */}
              </NavbarItem>
            )
          )
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {secondNavbarConfig(intl).map((n, index) => {
          return (
            n.intl_id && (
              <NavbarItem
                hasChild={!!secondNavbarConfig(intl)[index].dropdown}
                key={index.toString()}
                title={intl.formatMessage({ id: n.intl_id })}
                index={index}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                active={activeIndices === index ? "active" : ""}
                link={n.link}
                rel={n.rel}
                intl={intl}
              >
                {/* {activeIndices === index && CurrentDropdown && (
                <CurrentDropdown onMouseLeave={onMouseLeave} />
              )} */}
              </NavbarItem>
            )
          )
        })}
      </div>
    </Navbar>
  )
}

export default withUserContext(injectIntl(AnimatedNavbar))
